/*jshint esversion: 6 */
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import bootstrap from 'bootstrap';
import Cropper from 'cropperjs';
import swal from 'sweetalert';
//import { WOW } from 'wowjs';



$(document).ready(function() {
  "use strict";

  /*++++++++++++++++++++++++
   * +++++++ GAELERIE ++++++
   * +++++++++++++++++++++++
   */
  $("a[href$='.jpg'],a[href$='.png'],a[href$='.gif']").attr('data-gallery', '');
  $('#news-content img').addClass('img-fluid');
  $('.blog-box img').addClass('img-fluid');

  //carousel
  $('#carouselMessageControls').carousel({
    interval: 10000
  });

  /*+++++++++++++++++++++++++++++++++
   * +++++++ SCROLLUP ++++++
   * ++++++++++++++++++++++++++++++++
   */

  $('.scrollup').click(function() {
    $("html, body").animate({
      scrollTop: 0
    }, 1000);
    return false;
  });

  // Navigation Scroll
  $(window).scroll(function(event) {

    var loc = window.location;
    var first_path = loc.pathname;
    var n = first_path.lastIndexOf("/") + 1;
    var seite = first_path.substr(n).replace('.php', '');
    if(seite == 'index' || seite == '' ) {
       Scroll();
    }

    if ($(this).scrollTop() > 100) {
      $('.scrollup').fadeIn();
      $("#nav-img").stop().addClass('nav-img-sm', 1000, "easeInOutQuad");
    } else {
      $('.scrollup').fadeOut();
      $("#nav-img").stop().removeClass('nav-img-sm', 1000, "easeInOutQuad");
    }

  });

  $('.navbar-collapse ul li a.start-nav').click(function() {
    $('html, body').animate({
      scrollTop: $(this.hash).offset().top - 79
    }, 1000);
    return false;
  });

  $('.box-wrapper a').click(function() {
    $('html, body').animate({
      scrollTop: $(this.hash).offset().top - 79
    }, 1000);
    return false;
  });

  function Scroll() {
    var contentTop = [];
    var contentBottom = [];
    var winTop = $(window).scrollTop();
    var rangeTop = 200;
    var rangeBottom = 500;

    $('.navbar-collapse').find('.scroll a.start-nav').each(function() {
      contentTop.push($($(this).attr('href')).offset().top);
      contentBottom.push($($(this).attr('href')).offset().top + $($(this).attr('href')).height());
    });

    $.each(contentTop, function(i) {
      if (winTop > contentTop[i] - rangeTop) {
        $('.navbar-collapse li.scroll')
          .removeClass('active')
          .eq(i).addClass('active');
      }
    });
  }



}); //document
